import React from 'react';
import useAfterLoad from '@/hooks/useAfterLoad';
import { Spin } from 'antd';
import { Outlet } from 'react-router-dom';

export default function AppContainer() {
  const loading = useAfterLoad();

  return (
    <Spin spinning={loading}>
      <Outlet />
    </Spin>
  );
}
