import { ComponentType, ForwardRefExoticComponent, SVGProps } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '@ant-design/icons';
import css from './TopNavLink.module.scss';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

interface Props {
  icon:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>;
  activeIcon?:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>;
  title: string;
  className?: string;
  to: string;
}

export default function InsightNavLink({ to, icon, activeIcon, title }: Props) {
  return (
    <NavLink to={to} className={({ isActive }) => (isActive ? css.underline : '')}>
      {({ isActive }) => (
        <span className={css.EmsNav}>
          <Icon
            style={{ fontSize: 16, position: 'relative', zIndex: 2, color: isActive ? '#ffffff' : '#7D7F8A' }}
            component={isActive ? activeIcon : icon}
          />
          <span className={css.title}>{ " " + title }</span>
        </span>
      )}
    </NavLink>
  );
}
