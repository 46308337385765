import { DEFAULT_API_PREFIX } from '@/constants/apiPrefixes';
import { get, post } from '@/utils/requestUtils';
import {
  DeletePhotovoltaicAlarmrReq,
  GetAlarmUserInfoResp,
  GetPhotovoltaicAlarmPageReq,
  GetPhotovoltaicAlarmPageResp,
  PhotovoltaicAlarmItem
} from './type';

export async function getPhotovoltaicAlarmPage(
  params: GetPhotovoltaicAlarmPageReq
) {
  return post<GetPhotovoltaicAlarmPageResp>('/alert/record/pageQuery', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function ignoreAlarmItem(
  params: DeletePhotovoltaicAlarmrReq
) {
  return get('/alert/record/ignore', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}

export async function deleteAlarmItem(
  params: DeletePhotovoltaicAlarmrReq
) {
  return get('/alert/record/delete', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}
export async function getPhotovoltaicAlarmDetail(
  params: DeletePhotovoltaicAlarmrReq
) {
  return get('/alert/record/get', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}

export async function handlePhotovoltaicAlarmItem(
  params: PhotovoltaicAlarmItem
) {
  return post('/alert/record/handle', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function getAlarmUserInfo() {
  return get<GetAlarmUserInfoResp>('/alert/user/info', {
    prefix: DEFAULT_API_PREFIX,
    query: {}
  });
}

export async function updateAlarmUserInfo(params: string[]) {
  return post('/alert/user/update', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}
