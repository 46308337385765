import { useAppSelector } from '@/hooks/reduxHooks';
import { ReactComponent as ArrowSVG } from '@/icons/border/down.svg';
import {
  ChildrenModule,
  ModuleListItem
} from '@/services/systemSetting/menu/type';
import colorVariables from '@/styles/colors.scss';
import Icon from '@ant-design/icons';
import { ConfigProvider, Dropdown } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import css from './PageLayout.module.scss';

const PageLayout = memo(({ parentPath }: { parentPath: string }) => {
  const userInfo = useAppSelector(state => state.system.userInfo);
  const location = useLocation();
  const [routs, setRouts] = useState<ModuleListItem[]>([]);

  // 根据获取到用户权限route，计算出tree结构
  const getRouteTree = useCallback(
    (list: ChildrenModule[], path: string, level = 1) => {
      const arr: ModuleListItem[] = [];
      list?.forEach(item => {
        if (
          item.path.split('/').length === level + 1 &&
          item.path.startsWith(path)
        ) {
          arr.push({
            ...item,
            childrenList: getRouteTree(list, item.path, level + 1)
          });
        }
      });

      return arr.sort((x, y) => {
        return (x?.sortNo || 0) - (y?.sortNo || 0);
      });
    },
    []
  );

  useEffect(() => {
    const routerTree = getRouteTree(userInfo?.moduleInfoList, parentPath);
    setRouts(routerTree);
  }, [getRouteTree, parentPath, userInfo?.moduleInfoList]);

  const getBreadCrumb = useCallback(
    (routeData: ModuleListItem[]) => {
      const pathName = location.pathname;
      let arr: string[] = [];
      for (let i = 0; i < routeData.length; i++) {
        if (pathName.includes(routeData[i].path)) {
          arr.push(routeData[i].name);
          if (
            routeData[i].childrenList &&
            routeData[i].childrenList.length > 0
          ) {
            const tmpArr = getBreadCrumb(routeData[i].childrenList);
            arr = [...arr, ...tmpArr];
          }
          break;
        }
      }
      return arr;
    },
    [location.pathname]
  );

  const breadCrumb = useCallback(() => {
    const data = getBreadCrumb(routs);
    const dom = data.map((item, index) => {
      if (index === data.length - 1) {
        return (
          <span style={{ color: '#fff' }} key={item}>
            {item}
          </span>
        );
      } else {
        return (
          <span key={item}>
            {item}
            <span style={{ margin: '0 11px' }}>/</span>
          </span>
        );
      }
    });
    return dom;
  }, [getBreadCrumb, routs]);

  return (
    <div className={css.EnergyMonitoringLayout}>
      <ConfigProvider
        theme={{
          token: {
            colorBgBase: colorVariables.backgroundPopoverBg,
            colorText: colorVariables.whitePrimaryText,
            borderRadius: 4,
            borderRadiusLG: 8
          },
          components: {
            Dropdown: {
              controlItemBgHover: colorVariables.whiteHover
            }
          }
        }}
      >
        <div className={css.header}>
          <nav>
            {routs[0]?.childrenList?.map((item: ModuleListItem) => {
              if (item.childrenList && item.childrenList.length > 0) {
                return (
                  <Dropdown
                    key={item.id}
                    trigger={['click']}
                    menu={{
                      items: item.childrenList.map(ele => ({
                        key: ele.id,
                        label: (
                          <NavLink to={ele.path} className="highlight">
                            <span className={css.item}>{ele.name}</span>
                          </NavLink>
                        )
                      }))
                    }}
                    overlayClassName="header-dropdown"
                  >
                    <NavLink
                      to={item.path.split('/')[2]}
                      className="realtime-monitoring-nav-button"
                    >
                      <span className={css.item}>
                        {item.name}
                        <Icon
                          style={{ fontSize: 16, marginLeft: 4 }}
                          className="realtime-monitoring-nav-button-icon"
                          component={ArrowSVG}
                        />
                      </span>
                    </NavLink>
                  </Dropdown>
                );
              } else {
                return (
                  <NavLink
                    key={item.id}
                    to={item.path.split('/')[2]}
                    className={({ isActive }) =>
                      isActive ? css.active : ''
                    }
                  >
                    <span className={css.item}>{item.name}</span>
                  </NavLink>
                );
              }
            })}
          </nav>
        </div>
      </ConfigProvider>
      <div className={css.line}></div>
      <div className={css.breadCrumb}>{breadCrumb()}</div>
      <div className={css.layoutContent}>
        <Outlet />
      </div>
    </div>
  );
});

export default PageLayout;
