import NoAccess from '@/component/NoAccess/NoAccess';
import Notify from '@/component/Notify/Notify';
import TopNavLink from '@/component/TopNavLink/TopNavLink';
import { useAppSelector } from '@/hooks/reduxHooks';
import { ReactComponent as DownDropSVG } from '@/icons/border/downdrop.svg';
import Logo from '@/images/logo-lg.png';
import { logout } from '@/services/common/common.service';
import Icon, * as antdIcons from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import { useRequest } from 'kymatrix-hooks';
import _ from 'lodash';
import { Suspense, memo, useCallback, useMemo } from 'react';
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';
import css from './NormalLayout.module.scss';

interface Props {
  is404?: boolean;
}

const NormalLayout = memo(({ is404 }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useAppSelector(state => state.system.userInfo);
  const topLevelMenus = useMemo(() => {
    return userInfo?.moduleInfoList?.filter(
      item => item.path.split('/').length === 2
    );
  }, [userInfo?.moduleInfoList]);
  const sortedModules = useMemo(
    () => _.orderBy(topLevelMenus, 'sortNo'),
    [topLevelMenus]
  );

  const hasAccess = useMemo(() => {
    const { pathname } = location;
    const allPath = userInfo?.moduleInfoList?.map(i => i.path) ?? [];

    if (allPath.concat(['/']).includes(pathname)) {
      return true;
    }
    return false;
  }, [location, userInfo?.moduleInfoList]);

  const logoutFunc = useRequest(logout, {
    manual: true,
    onSuccess: res => {
      if (res.code === 200) {
        localStorage.removeItem('token');
        navigate('/user/login');
      }
    }
  });

  const handleLogout = useCallback(() => {
    logoutFunc.run();
  }, [logoutFunc]);
  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '3') {
      handleLogout();
    }
  };
  const items: MenuProps['items'] = [
    {
      key: '3',
      label: <span className={css.item}>退出登录</span>
    }
  ];
  return (
    <div className={css.NormalLayout}>
      <div className={css.header}>
        <div className={css.logo} onClick={() => navigate('/')}>
          <img src={Logo} alt="" height={48} title="震坤智能" />
        </div>
        <div className={css.menu}>
          <nav>
            {sortedModules.map(item => (
              <TopNavLink
                key={item.id}
                to={item?.path}
                icon={(antdIcons as any)[item.icon]}
                activeIcon={(antdIcons as any)[item.icon]}
                title={item.name}
              />
            ))}
          </nav>
        </div>
        <div className={css.userCenter}>
          <Notify style={{ marginRight: 20 }} />
          <Dropdown
            trigger={['click']}
            menu={{ items, onClick }}
            overlayClassName="header-dropdown"
          >
            <NavLink to="" className="realtime-monitoring-nav-button">
              <span className={css.item}>
                {userInfo.username}
                <Icon
                  style={{ fontSize: 16, marginLeft: 4 }}
                  className="realtime-monitoring-nav-button-icon"
                  component={DownDropSVG}
                />
              </span>
            </NavLink>
          </Dropdown>
        </div>
      </div>
      <div className={css.layoutContent}>
        <Suspense fallback={<Spin spinning className={css.loading} />}>
          {!hasAccess && userInfo.moduleInfoList && !is404 ? (
            <NoAccess />
          ) : (
            <Outlet />
          )}
        </Suspense>
      </div>
    </div>
  );
});

export default NormalLayout;
