import { UserInfo } from '@/services/common/type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SystemState = {
  userInfo: UserInfo;
  fetchUserInfoLoading: boolean;
};

/**
 * 全局用户信息部分
 */

export const systemSlice = createSlice({
  name: 'systemInfo',
  initialState: {
    userInfo: {},
    fetchUserInfoLoading: false
  } as SystemState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    updateFetchUserInfoLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.fetchUserInfoLoading = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateUserInfo, updateFetchUserInfoLoading } = systemSlice.actions;

export default systemSlice.reducer;
