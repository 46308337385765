import { getPhotovoltaicAlarmPage } from '@/services/alarmManagement/photovoltaicAlarm/photovoltaicAlarm.service';
import { PhotovoltaicAlarmItem } from '@/services/alarmManagement/photovoltaicAlarm/type';
import { Badge, Dropdown } from 'antd';
import { useRequest } from 'kymatrix-hooks';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import css from './Notify.module.scss';

interface Props {
  style?: React.CSSProperties;
}

export default function Notify({ style }: Props) {
  const navigate = useNavigate();
  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<PhotovoltaicAlarmItem[]>([]);
  useRequest(
    (params = { current: 1, pageSize: 10, statusArray: ['0'] }) =>
      getPhotovoltaicAlarmPage(params),
    {
      pollingInterval: 10000, //轮训间隔
      pollingErrorRetryCount: 3, //轮训失败重试次数
      onSuccess: res => {
        setList(res.result.list);
        setTotal(res.result.total);
      }
    }
  );

  return (
    <div style={style} className={css.container}>
      <Dropdown
        placement="bottom"
        overlayStyle={{ width: '300px' }}
        dropdownRender={() => {
          return (
            <div className={css.notifyContainer}>
              {list.length === 0 && (
                <div style={{ textAlign: 'center' }}>暂无数据</div>
              )}
              {list.map(i => {
                return (
                  <div className={css.notifyItem} key={i.id}>
                    <div style={{ width: 200 }}>
                      <div className={css.inverterName}>
                        逆变器名称: {i.inverterName}
                      </div>
                      <div className={css.inverterCode}>
                        逆变器编码: {i.inverterCode}
                      </div>
                      <div className={css.alertContent}>
                        &nbsp;&nbsp;&nbsp;&nbsp;告警内容: {i.alertContent}
                      </div>
                    </div>
                    <div
                      className={css.viewContainer}
                      onClick={() => {
                        navigate('/alarm-management/photovoltaic-alarm');
                      }}
                    >
                      查看
                    </div>
                  </div>
                );
              })}
              {total > 10 && (
                <div
                  className={css.notifyItem}
                  style={{ justifyContent: 'center' }}
                >
                  <NavLink to={'/alarm-management/photovoltaic-alarm'}>
                    查看全部告警
                  </NavLink>
                </div>
              )}
            </div>
          );
        }}
        trigger={['click']}
      >
        <div style={{ cursor: 'pointer' }}>
          <Badge count={total || 0} showZero={false} size="small">
            <div
              className={css.notifyIcon}
              style={{ backgroundImage: `url('/images/notify.png')` }}
            ></div>
          </Badge>
        </div>
      </Dropdown>
    </div>
  );
}
