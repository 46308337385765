import { get, post } from '@/utils/requestUtils';
import { DEFAULT_API_PREFIX } from '@/constants/apiPrefixes';
import {
  FetchUserInfoResp,
  GetDsictionaryResp,
  LoginRequest,
  getDsictionaryReq
} from './type';

export async function randomImage(params: { time: number }) {
  return get<{ image: string }>(`/sys/randomImage/${params.time}`, {
    query: { _t: Math.floor(params.time / 1000) },
    prefix: DEFAULT_API_PREFIX
  });
}

export async function login(params: LoginRequest) {
  return post<{ token: string }>('/login/doLogin', {
    data: params,
    prefix: DEFAULT_API_PREFIX
  });
}

export async function fetchUserInfo() {
  return get<FetchUserInfoResp>('/user/auth', {
    prefix: DEFAULT_API_PREFIX
  });
}

export async function logout() {
  return post('login/logout', {
    prefix: DEFAULT_API_PREFIX
  });
}

export async function getDsictionary(params: getDsictionaryReq) {
  return post<GetDsictionaryResp>('/sys/config/list', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}
