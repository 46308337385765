import { lazy, useCallback, useMemo } from 'react';

import NormalLayout from '@/layouts/NormalLayout/NormalLayout';
import colorVariables from '@/styles/colors.scss';
import 'antd/dist/reset.css';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import Login from './pages/User/Login/Login';
// import variables from "@/styles/variables.scss";
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import AppContainer from './component/AppContainer/AppContainer';
import { useAppSelector } from './hooks/reduxHooks';
import PageLayout from './layouts/PageLayout/PageLayout';

// 系统管理
const DataManage = lazy(
  () => import('./pages/SystemSetting/DataManage/DataManage')
);
const MenuManage = lazy(
  () => import('./pages/SystemSetting/MenuManage/MenuManage')
);
const RoleManage = lazy(
  () => import('./pages/SystemSetting/RoleManage/RoleManage')
);
const UserManage = lazy(
  () => import('./pages/SystemSetting/UserManage/UserManage')
);
// 光伏管理
const PhotovoltaicAlarm = lazy(
  () =>
    import('./pages/AlarmManagement/PhotovoltaicAlarm/PhotovoltaicAlarm')
);
const PhotovoltaicDashboard = lazy(
  () =>
    import('./pages/Dashboard/PhotovoltaicDashboard/PhotovoltaicDashboard')
);
const InverterStationReport = lazy(
  () =>
    import(
      './pages/DataAnalysis/Photovoltaic/InverterStationReport/InverterStationReport'
    )
);
const PowerStationReport = lazy(
  () =>
    import(
      './pages/DataAnalysis/Photovoltaic/PowerStationReport/PowerStationReport'
    )
);
const Photovoltaic = lazy(
  () => import('./pages/LargeMonitoringScreen/Photovoltaic/Photovoltaic')
);

const InverterManagement = lazy(
  () =>
    import(
      './pages/PhotovoltaicManagement/InverterManagement/InverterManagement'
    )
);
const PowerStationManagement = lazy(
  () =>
    import(
      './pages/PhotovoltaicManagement/PowerStationManagement/PowerStationManagement'
    )
);
const PowerStationStatus = lazy(
  () =>
    import(
      './pages/PhotovoltaicManagement/PowerStationStatus/PowerStationStatus'
    )
);
const SvgManagement = lazy(
  () =>
    import('./pages/PhotovoltaicManagement/SvgManagement/SvgManagement')
);
const NotFoundPage = lazy(
  () => import('./pages/NotFoundPage/NotFoundPage')
);

dayjs.locale('zh-cn'); // 使用本地化语言

function App() {
  const userInfo = useAppSelector(state => state.system.userInfo);
  const modules = useMemo(
    () => userInfo.moduleInfoList,
    [userInfo.moduleInfoList]
  );
  const activeMenu = useCallback(
    (route: string) => {
      const module = modules?.filter(
        item => item?.path.startsWith(route) && item.path !== route
      );
      if (route === '/' && modules) {
        return modules?.[0]?.path || route;
      }
      if (module) {
        module.sort((a, b) => a.sortNo - b.sortNo);
        const childrenModule = module?.[0];
        return childrenModule?.path || route;
      } else {
        return route;
      }
    },
    [modules]
  );

  return (
    <ConfigProvider
      locale={zhCN}
      autoInsertSpaceInButton={false}
      theme={{
        token: {
          colorTextBase: colorVariables.whiteTitle,
          colorPrimary: colorVariables.primary_7,
          borderRadius: 4,
          borderRadiusLG: 8,
          fontFamily:
            "'HarmonyOS Sans SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
        },
        components: {
          Button: {
            colorPrimaryActive: colorVariables.primary_5,
            colorPrimaryHover: colorVariables.primary_6,
            colorTextDisabled: colorVariables.whiteDisable,
            colorBgContainerDisabled: '#444A63'
          },
          Modal: {
            colorBgElevated: colorVariables.backgroundCardBg,
            colorText: colorVariables.whiteTitle,
            colorTextHeading: colorVariables.whiteTitle,
            paddingContentHorizontalLG: 0,
            paddingMD: 0,
            colorTextDescription: colorVariables.whiteSecondaryText,
            colorIconHover: colorVariables.whiteTitle
            // colorTextTertiary: colorVariables.whiteSecondaryText
          },
          Table: {
            colorBorderSecondary: colorVariables.whiteDividers,
            colorFillAlter: colorVariables.whiteTableHeader,
            colorBgContainer: colorVariables.transparent,
            colorTextHeading: colorVariables.whiteTitle,
            colorText: colorVariables.whitePrimaryText,
            fontWeightStrong: 500
          },
          Pagination: {
            colorText: colorVariables.whitePrimaryText,
            colorBgContainer: colorVariables.whiteCard,
            colorTextDisabled: colorVariables.whitePrimaryText
            // colorBorder: colorVariables.whiteBorder,
          },
          Select: {
            colorBgContainer: colorVariables.whiteCard,
            colorText: colorVariables.whitePrimaryText,
            colorTextPlaceholder: colorVariables.whiteSecondaryText,
            colorBorder: colorVariables.whiteBorder,
            colorBgElevated: colorVariables.backgroundPopoverBg,
            controlItemBgActive: colorVariables.whiteHover,
            colorTextQuaternary: colorVariables.whitePrimaryText
          },
          TreeSelect: {
            colorBgContainer: colorVariables.whiteCard,
            colorText: colorVariables.whitePrimaryText,
            colorTextPlaceholder: colorVariables.whiteSecondaryText,
            colorBorder: colorVariables.whiteBorder,
            colorBgElevated: colorVariables.backgroundPopoverBg,
            controlItemBgActive: colorVariables.whiteHover,
            colorTextQuaternary: colorVariables.whitePrimaryText
          },
          Popover: {
            // colorPrimaryActive: colorVariables.primary_5,
            // colorPrimaryHover: colorVariables.primary_6,
            // colorTextDisabled: colorVariables.whiteDisable,
            // colorBgContainerDisabled: '#444A63',
            colorBgElevated: colorVariables.backgroundPopoverBg
          },
          Dropdown: {
            controlItemBgHover: colorVariables.whiteHover,
            colorText: colorVariables.whitePrimaryText,
            colorBgElevated: colorVariables.backgroundPopoverBg
          },
          Radio: {
            colorBgContainer: colorVariables.whiteCard,
            colorBorder: colorVariables.whiteBorder
          },
          Tabs: {
            colorText: colorVariables.whiteTitle
          },
          Anchor: {
            colorText: colorVariables.whiteSecondaryText,
            colorPrimary: colorVariables.whiteTitle,
            colorSplit: colorVariables.whiteDividers
          },
          Tree: {
            colorBgContainer: colorVariables.backgroundCardBg,
            colorText: colorVariables.whiteTitle,
            controlItemBgActive: colorVariables.primary_4
          },
          DatePicker: {
            colorBgContainer: colorVariables.whiteCard,
            colorBorder: colorVariables.whiteBorder,
            colorSplit: colorVariables.whiteDividers,
            colorText: colorVariables.whiteTitle,
            colorTextHeading: colorVariables.whiteTitle,
            colorTextPlaceholder: colorVariables.whiteSecondaryText,
            controlOutline: colorVariables.whiteBorder,
            colorIcon: colorVariables.whiteSecondaryText,
            colorTextDisabled: colorVariables.whiteSecondaryText,
            colorBgElevated: colorVariables.backgroundPopoverBg
          },
          Input: {
            colorText: colorVariables.whiteTitle,
            colorBgContainer: colorVariables.whiteCard,
            colorBorder: colorVariables.whiteBorder,
            controlHeight: 38.9
          }
        }
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppContainer />}>
            {/* 普通布局 */}
            <Route index element={<Navigate to={activeMenu('/')} />} />
            <Route path="/" element={<NormalLayout />}>
              {/* 监控大屏 */}
              <>
                <Route
                  path="large-monitoring-screen"
                  element={
                    <PageLayout parentPath="/large-monitoring-screen" />
                  }
                >
                  <Route
                    index
                    element={
                      <Navigate
                        to={activeMenu('/large-monitoring-screen')}
                      />
                    }
                  />
                  <Route path="photovoltaic" element={<Photovoltaic />} />
                </Route>
              </>

              {/* 首页 */}
              <>
                <Route
                  path="dashboard"
                  element={<PageLayout parentPath="/dashboard" />}
                >
                  <Route
                    index
                    element={<Navigate to={activeMenu('/dashboard')} />}
                  />
                  <Route
                    path="photovoltaic"
                    element={<PhotovoltaicDashboard />}
                  />
                </Route>
              </>

              {/* 数据分析 */}
              <>
                <Route
                  path="data-analysis"
                  element={<PageLayout parentPath="/data-analysis" />}
                >
                  <Route
                    index
                    element={
                      <Navigate to={activeMenu('/data-analysis')} />
                    }
                  />
                  <Route
                    path="power-station-report"
                    element={<PowerStationReport />}
                  />
                  <Route
                    path="inverter-report"
                    element={<InverterStationReport />}
                  />
                </Route>
              </>

              {/* 系统设置 */}
              <Route
                path="system-setting"
                element={<PageLayout parentPath="/system-setting" />}
              >
                <Route
                  index
                  element={<Navigate to={activeMenu('/system-setting')} />}
                />
                <Route path="role-management" element={<RoleManage />} />
                <Route path="user-management" element={<UserManage />} />
                <Route path="menu-management" element={<MenuManage />} />
                <Route path="data-management" element={<DataManage />} />
              </Route>
              {/* 光伏管理 */}
              <Route
                path="photovoltaic-management"
                element={
                  <PageLayout parentPath="/photovoltaic-management" />
                }
              >
                <Route
                  index
                  element={
                    <Navigate
                      to={activeMenu('/photovoltaic-management/')}
                    />
                  }
                />
                <Route
                  path="powerStation-management"
                  element={<PowerStationManagement />}
                />
                <Route
                  path="inverter-management"
                  element={<InverterManagement />}
                />
                <Route
                  path="powerStation-status"
                  element={<PowerStationStatus />}
                />
                <Route path="svg-management" element={<SvgManagement />} />
              </Route>
              {/* 告警管理 */}
              <Route
                path="alarm-management"
                element={<PageLayout parentPath="/alarm-management" />}
              >
                <Route
                  index
                  element={
                    <Navigate to={activeMenu('/alarm-management')} />
                  }
                />
                <Route
                  path="photovoltaic-alarm"
                  element={<PhotovoltaicAlarm />}
                />
              </Route>
            </Route>
            {/* 登录 */}
            <Route path="/user/login" element={<Login />}></Route>
            {/* <Route path="/404" element={<NotFoundPage />} /> */}
            <Route path="*" element={<AppContainer />}>
              <Route path="*" element={<NormalLayout is404 />}>
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
