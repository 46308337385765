import React, { memo } from 'react';
import noAccessImg from './no-access.png';
import css from './NoAccess.module.scss';

const NoAccess = memo(() => {
  return (
    <div className={css.NoAccess}>
      <div className={css.img} style={{ backgroundImage: `url(${noAccessImg})` }} />
      <span>暂未开通权限请联系管理员</span>
    </div>
  );
});

export default NoAccess;
