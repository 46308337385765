import {
  updateFetchUserInfoLoading,
  updateUserInfo
} from '@/redux/systemSlice';
import { fetchUserInfo } from '@/services/common/common.service';
import { useBackLink, useRequest } from 'kymatrix-hooks';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from './reduxHooks';

/**
 * 项目初始化需要加载的东西
 */

export default function useAfterLoad() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getBackLink, resolveBackLink } = useBackLink();
  const location = useLocation();

  // 拉取用户信息
  const { run: getRequestUserAsync, loading } = useRequest(fetchUserInfo, {
    manual: true,
    onSuccess: userInfoResult => {
      dispatch(updateUserInfo(userInfoResult.data));

      const allPath =
        userInfoResult.data.moduleInfoList?.map(i => i.path) ?? [];

      // let link: string | undefined =
      //   userInfoResult.data.moduleInfoList?.[0].path ?? '/';
      let link: string | undefined;

      if (allPath.includes(location.pathname)) {
        link = location.pathname;
      }

      if (location.pathname === '/user/login') {
        const backLink = resolveBackLink();
        if (backLink && allPath.includes(location.pathname)) {
          link = backLink;
        } else {
          link = userInfoResult.data.moduleInfoList?.[0].path;
        }
      }
      if (link) {
        navigate(link);
      }
    },
    onError: (e: any) => {
      if (
        e.status === 401 ||
        e.status === 403 ||
        e.response.status === 401 ||
        e.response.status === 403
      ) {
        localStorage.removeItem('token');
        if (location.pathname !== '/user/login') {
          navigate(`/user/login${getBackLink()}`);
        }
      }
    }
  });

  useEffect(() => {
    dispatch(updateFetchUserInfoLoading(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getRequestUserAsync();
    } else {
      navigate('/user/login');
    }
  }, [getRequestUserAsync, navigate]);

  return loading;
}
