import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from '@/redux/store';
import { Provider } from 'react-redux';
import zhCN from 'antd/lib/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import reportWebVitals from './reportWebVitals';
import '@/styles/normalize.scss';
import '@/styles/colors.scss';
import { ConfigProvider } from 'antd';

dayjs.locale('zh-cn');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
