import { useAppDispatch } from '@/hooks/reduxHooks';
import { ReactComponent as lockSVG } from '@/icons/border/lock.svg';
import { ReactComponent as safeSVG } from '@/icons/border/safe.svg';
import { ReactComponent as userSVG } from '@/icons/border/user.svg';
import logo from '@/images/logo.png';
import { updateUserInfo } from '@/redux/systemSlice';
import {
  fetchUserInfo,
  login,
  randomImage
} from '@/services/common/common.service';
import { LoginRequest } from '@/services/common/type';
import colorVariables from '@/styles/colors.scss';
import Icon from '@ant-design/icons';
import { Button, Checkbox, ConfigProvider, Form, Input } from 'antd';
import { useRequest } from 'kymatrix-hooks';
import React, { useCallback, useState } from 'react';
import css from './Login.module.scss';

const Login = React.memo(() => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const [time, setTime] = useState<number>(new Date().getTime());

  const randomImageRequest = useRequest((value = time) =>
    randomImage({ time: value })
  );
  const loginRequest = useRequest((data: LoginRequest) => login(data), {
    manual: true
  });
  const fetchUserInfoRequest = useRequest(fetchUserInfo, { manual: true });

  const onFinish = async (values: LoginRequest) => {
    try {
      const data = await loginRequest.runAsync({
        ...values,
        checkKey: time
      });

      localStorage.setItem('token', data?.result.token);

      const userInfoResult = await fetchUserInfoRequest.runAsync();
      dispatch(updateUserInfo(userInfoResult.result));

      window.location.reload();
    } catch (e) {
      reloadCaptcha();
    }
  };

  const reloadCaptcha = useCallback(() => {
    form.setFieldsValue({ captcha: '' });
    setTime(new Date().getTime());
    randomImageRequest.run(new Date().getTime());
  }, [form, randomImageRequest]);

  return (
    <div className={css.Login}>
      <div className={css.loginForm}>
        <div style={{ marginTop: 56 }}>
          <img src={logo} alt="" height={56} />
        </div>
        <span className={css.txt}>用户登录 / USER LOGIN</span>
        <div className={css.line}></div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorText: colorVariables.whiteTitle,
                colorBgContainer: colorVariables.whiteCard,
                colorBorder: colorVariables.whiteBorder,
                controlHeight: 38.9
              }
            }
          }}
        >
          <Form
            form={form}
            name="basic"
            layout="vertical"
            style={{ width: 339 }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{ autoLoginFlag: false }}
          >
            <Form.Item
              label="用户账号"
              colon={false}
              name="username"
              rules={[{ required: true, message: '请输入账号！' }]}
            >
              <Input
                prefix={
                  <Icon style={{ fontSize: 14 }} component={userSVG} />
                }
                placeholder="请输入账号"
              />
            </Form.Item>

            <Form.Item
              label="登录密码"
              colon={false}
              name="password"
              rules={[{ required: true, message: '请输入密码！' }]}
            >
              <Input.Password
                prefix={
                  <Icon style={{ fontSize: 14 }} component={lockSVG} />
                }
                placeholder="请输入密码"
              />
            </Form.Item>

            <Form.Item>
              <Form.Item
                label="验证码"
                colon={false}
                name="captcha"
                rules={[{ required: true, message: '请输入验证码！' }]}
                style={{ display: 'inline-block' }}
                noStyle
              >
                <Input
                  style={{ width: '247px' }}
                  prefix={
                    <Icon style={{ fontSize: 14 }} component={safeSVG} />
                  }
                  placeholder="请输入验证码"
                />
              </Form.Item>
              <Form.Item
                label=" "
                style={{ display: 'inline-block' }}
                noStyle
              >
                <img
                  width={76}
                  height={38}
                  src={randomImageRequest.data?.result?.image}
                  onClick={reloadCaptcha}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label=""
              name="autoLoginFlag"
              valuePropName="checked"
            >
              <Checkbox>保持登录</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                // style={{ width: '339px', height: '40px', position: 'relative', top: '-24px' }}
                type="primary"
                htmlType="submit"
                block
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </div>
    </div>
  );
});

export default Login;
